import React, {useState, useRef, useEffect, Fragment} from 'react';
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import '../../../../styles/order_icon.css';

import {
    faSliders,
    faRightFromBracket
} from "@fortawesome/free-solid-svg-icons";


import {cryptoJs} from 'crypto-js';
/*
import {
    Button,
    Row,
    Col,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    Jumbotron,
    FormFeedback,
    Spinner,
    Tooltip,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavItem, Nav, UncontrolledAlert, Alert
} from "reactstrap";
*/

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import DropdownItem from 'react-bootstrap/DropdownItem';
import Nav from "react-bootstrap/Nav";
import {gql, useQuery} from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faShoppingCart,
    faUser,
    faUserSlash
} from "@fortawesome/free-solid-svg-icons";


import '../../../../styles/app.user.theme.css';
import AppAuthLoginFormModal from "../../../auth/app.auth.loginform.modal";
import {cancelLastRequest, checkUser} from "../../../auth/app.auth.actions";
import AppAuthLogoutForm from '../../../auth/app.auth.logoutform.modal';
import {buildError} from "../../../auth/app.auth.lib";
import {useAuthDispatch, useAuthState} from "../../../auth/app.auth.context";
import {Overlay, OverlayTrigger} from "react-bootstrap";
import {node} from "prop-types";

/*
const QUERY_PRINCIPAL = gql`
    {
        principal{
          name
        }
    }`
*/

const ButtonUser = (props) => {
    return(
        <button
            type="button"
            onClick={props.onClick}
            className="nav-item nav-link d-none d-md-block"
        >
            <svg width="24" height="24"><use xlinkHref="#icon-profile"/></svg>
            <small className="d-none d-md-block">войти</small>
            {/*!props.showWait && <small className="d-none d-md-block">войти</small>*/}
        </button>
    )
}

const UserCtrl = (props) => {
    let login_user = props.login_user;
    let loading = props.onLoading;
    let error = props.error;
    let onLoginClick = props.onLoginClick;
    let onLogoutClick = props.onLogoutClick;
    let button_user_component = <div></div>

    let navigate = useNavigate();

    const el_ref = useRef(null);
    const [tooltipOpen,setToolTipOpen] = useState(false);

    const toggleTooltip = () => { setToolTipOpen(!tooltipOpen)};

    const [dropdownOpen,setDropdownOpen] = useState(false);

    const toggleDropDown = () => {setDropdownOpen(!dropdownOpen)};




    // обработка в процессе выполнения запроса
    // включение прелоадера, например


    const profileClick = () => {

        window.location.assign("/profile");
    }

    const menuClick = () => {

        setDropdownOpen(true);
    }

    const menuToggle =(e) => {
        toggleDropDown();
    }

    let timerHandler = null; 

    const menuOpen = (e)=>{
        if(timerHandler){
            clearTimeout(timerHandler);
        }

        if(dropdownOpen === false){
            setDropdownOpen(true);
        }
    }
    const menuClose = (e)=>{
        timerHandler = setTimeout( () => {
            if(dropdownOpen === true){
                setDropdownOpen(false);
            }
        },100)
    }

    const getTgLink = () => {
        return window?.__env?.TGRM_URL;
    }

    const getTgQrLink = () =>{
        return window?.__env?.TGRM_QR_URL;
    }

    const getTgQrSvgLink = () => {
        return window?.__env?.TGRM_QR_SVG_URL;
    }

    if (loading) {
        button_user_component = <ButtonUser showWait={true}/>
    } else {
        // обработка ошибки

        if (error) {
            // вывести ошибку

            const renderTooltip = (props) => (
                <Tooltip id="tt-gql-error"  {...props} >
                    {error}
                    <br/>
                    Что-то пошло не так на сервере. Перезагрузите страницу
                </Tooltip>
            );

            button_user_component =
                <div className="py-0 text-center text-dark">
                    <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                        <span ref={el_ref}>
                            <svg fill="red" width="24" height="24" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7ZM17 7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7ZM9 16C7.34315 16 6 17.3431 6 19V21H18V19C18 17.3431 16.6569 16 15 16H9ZM2.5 21H4V19C4 16.2386 6.23858 14 9 14H15C17.7614 14 20 16.2386 20 19V21H21.5C21.7761 21 22 21.2239 22 21.5C22 21.7761 21.7761 22 21.5 22H2.5C2.22386 22 2 21.7761 2 21.5C2 21.2239 2.22386 21 2.5 21Z"/>
                            </svg>
                        </span>
                    </OverlayTrigger>
                </div>;
        } else {
            // обработка полученных данных если не было ошибки

            if (login_user && (login_user.trim() !== '')) {
                // если получено имя пользователя
                button_user_component =

                    <Dropdown
                        show={dropdownOpen}
                        onMouseEnter={menuOpen}
                        onMouseLeave={menuClose}
                        align="end"
                        className="userDropdown"
                    >
                     
                        
                        <Dropdown.Toggle
                            as={Link}
                            to="#"
                            className=" nav-item  nav-link  d-none d-md-block  "
                        >

                            <svg fill="currentColor" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7ZM17 7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7ZM9 16C7.34315 16 6 17.3431 6 19V21H18V19C18 17.3431 16.6569 16 15 16H9ZM2.5 21H4V19C4 16.2386 6.23858 14 9 14H15C17.7614 14 20 16.2386 20 19V21H21.5C21.7761 21 22 21.2239 22 21.5C22 21.7761 21.7761 22 21.5 22H2.5C2.22386 22 2 21.7761 2 21.5C2 21.2239 2.22386 21 2.5 21Z"/>
                            </svg>

                            <small className="d-none d-md-block">профиль</small>
                        </Dropdown.Toggle>
            
                        <Dropdown.Menu show={dropdownOpen} renderOnMount className='' style={{margin:0}}>
                            {/*<Dropdown.Item onClick={profileClick}>Профиль {login_user}</Dropdown.Item>
                            <Dropdown.Divider/>*/}
                            
                            <Dropdown.Item
                                href={'/orders'}
                                onClick={() => location.assign('/orders')}>
                                <div className="d-flex">
                                    <div className="pe-2">
                                        <svg width="24" height="24"><use href="#icon-orders" xlinkHref="#icon-orders"/></svg>
                                    </div>
                                    <div>Заказы</div>
                                </div>
                            </Dropdown.Item>
                            <Dropdown.Item
                                href={'/myprofile'}
                                onClick={() => location.assign('/myprofile')}>
                                <div className="d-flex">
                                    <div className="pe-2"><FontAwesomeIcon icon={faSliders}  width="24" height="24" /></div>
                                    <div>Настройки профиля</div>
                                </div>

                            </Dropdown.Item>
                            <Dropdown.Header>
                                <div style={{display:"flex"}}>
                                    <div style={{minWidth:"200px", whiteSpace:"break-spaces", float:"right"}}>Для получения сообщений и кодов для входа подключите
                                        &nbsp;<a href={getTgLink()} className="text-warning">telegram бот</a>
                                    </div>

                                    <img height="65px"src={getTgQrLink()} srcSet={getTgQrSvgLink()}/>
                                </div>

                            </Dropdown.Header>

                            <Dropdown.Divider/>
                            <Dropdown.Item onClick={onLogoutClick}>
                                <div className="d-flex">
                                    <div className="pe-2"><FontAwesomeIcon icon={faRightFromBracket}  width="24" height="24"/></div>
                                    <div>Выход из личного кабинета</div>
                                </div>

                            </Dropdown.Item>
            
                        </Dropdown.Menu>
                    </Dropdown>

            } else {
                // если имя пользователя не получено - просим авторизоваться
                button_user_component = <ButtonUser onClick={onLoginClick}/>;
            }
        }
    }

    return (
        <Fragment>
            {button_user_component}
        </Fragment>
    );
}

const useFocus = () => {
    const htmlElRef = useRef(null)

    const setFocus = () => {
        //alert(1);
        if (htmlElRef.current) {
            //alert(1.4);
            htmlElRef.current.focus();
            //alert(1.6)
        }
        //alert(2);}
    }

    return [ htmlElRef, setFocus ]
}

// глобальная переменная для токена отмены запроса авторизации или выхода
// переопределяется перед выполнением запроса
let source = axios.CancelToken.source();

const User = (props) => {
    let logined = false;

    let navigate = useNavigate();

    const[showLogin,setShowLogin] = useState(false);
    const toggleLogin = () => {
        setShowLogin(!showLogin)
        cancelLastRequest().catch();
    };

    const [showLogout, setShowLogout] = useState(false);


    const [isOpenModalLogout, setIsOpenModalLogout] = useState(false);

    const toggleModalLogout = () => {setIsOpenModalLogout(!isOpenModalLogout)};

    let loginedUser = undefined;
    let queryError = undefined;


    const {userDetails, loading, errorMessage, lastAction} = useAuthState();
    //
    // const dispatch = useAuthDispatch();

    if (userDetails) {
        loginedUser = userDetails.phone;
    }




    //console.log('show');
    //console.log(showLogin);
    //console.log("*******");

    function loginClick(){

        //console.log(logined);
        //console.log("**********");
        setShowLogin(true);

    }



    function logoutClick(){
        //console.log('logout click');
        toggleModalLogout();
    }

    // этот пропс передан сверху что бы можно было управлять
    // отображением окна авторизации
    if(!userDetails?.phone){
        props.login_cb.toggleAuth = loginClick;
    }else{
        props.login_cb.toggleAuth = null;
    }

    return (
        <div>
            {/* компонент, который меняет состояние в зависимости от выполнения запроса GraphQL*/}
            <UserCtrl
                error={queryError}
                login_user={loginedUser}
                onLoading={loading}
                onLoginClick={loginClick}
                onLogoutClick={logoutClick}
            />
            <AppAuthLoginFormModal show={showLogin} toggle={toggleLogin} className="loginform"/>
            <AppAuthLogoutForm show={isOpenModalLogout} toggle={toggleModalLogout} />
        </div>
    )

};

export default User;
