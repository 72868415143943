import React, { useEffect } from 'react';
import Order from './orders';
import Cart from './cart';
import AppUserMain from './user';
import { checkUser } from '../../../auth/app.auth.actions';
import { buildError } from '../../../auth/app.auth.lib';
import { useAuthDispatch } from '../../../auth/app.auth.context';

const Main = (props) => {
    const dispatch = useAuthDispatch();

    const startUser = async () => {
        //console.log('start CheckUser');
        let response = await checkUser(dispatch);
        let response_data = response;
        //console.log('####### startUser #########');
        //console.log(response_data);
        //console.log('###########################');
        if (response_data?.data) {
            // данные пользователя
        }
        if (response_data?.error) {
            console.error(buildError(response_data));
        }
    };

    useEffect(() => {
        startUser().catch((e) => {
            console.error(e);
        });
    }, []);

    return (
        <nav className="nav">
            {/*<button
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#navbar"
                className="nav-item nav-link d-block d-md-none me-2"
                aria-label="Меню"
            >
                <svg width="32" height="32"><use xlinkHref="#icon-menu"/></svg>
            </button>*/}

            <Order/>
            {/*
                <div className="col-auto d-none d-lg-block px-0">
                    //<a href="" className="nav-link py-0 text-center text-muted">
                    <div className="nav-link  py-0 text-center text-muted">
                        <svg stroke="currentColor" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5 5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21L12 17.5L5 21V5Z"
                                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <small className="d-block">избранное</small>
                    </div>
                </div>
            */}
            <AppUserMain login_cb={props.login_cb}/>
            <Cart/>
        </nav>
    );
};


export default Main;
