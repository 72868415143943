import React, {useEffect, useRef, useState} from 'react';
import {useReactiveVar} from "@apollo/client";
import {cartTotal} from "../../../../config/react.vars";

const Cart = () => {
    //const cartTtl = useReactiveVar(cartTotal);

    let items = JSON.parse(localStorage.getItem('cartItems') ?? '[]');

    if (!items) items = [];

    let countItems = 0;
    items.map(item => {
        if(item?.count >-1){
            countItems += +item.count;
        }
    });

    /*
    if(cartTtl === -1) {
        cartTotal(countItems);
    }
    */

    const [stateCountItems, setStateCountItems ] = useState(countItems);

    const refCartCount = useRef();

    // Добавляем глобальное слушатель события об изменении cartItems в localStorage
    window.addEventListener('storage', function (e) {

        //console.log('eventListener worked');

        let items = JSON.parse(localStorage.getItem('cartItems') ?? '[]');
        let elementCountCartItems = document.getElementById('countCartItems');

        let countItems = 0;

        if (items && items.length > 0) {
            items.map(item => {
                if(item.count > -1){
                    countItems += +item.count;
                }
            });
            setStateCountItems(countItems);
            //console.log(countItems);
        } else {
            localStorage.setItem('cartItems', '[]');
            setStateCountItems(0);
        }

        //console.log('eventListener worked done');
    });

/*
    useEffect( () => {
        console.log('обновление корзины');
    })
*/

    return (
        <div id="btn_cart" className="cart-header-button">
            <a href="/cart" className="btn">
                <svg width="32" height="32"><use href="#icon-cart" xlinkHref="#icon-cart"/></svg>
                <span ref={refCartCount}
                      id="countCartItems"
                      className="position-absolute top-0 end-0 mt-n1 me-n1 badge rounded-pill bg-danger"
                >
                    {stateCountItems > 0 && stateCountItems}
                    {/*(cartTtl > 0) && cartTtl*/}
                </span>
            </a>
        </div>
    );
};

export default Cart;
