import React, {useState, Fragment, useEffect} from 'react';
import {gql, useLazyQuery, useQuery} from "@apollo/client";
import Spinner from "react-bootstrap/Spinner";
import '../../../../styles/order_icon.css';
import {useAuthDispatch, useAuthState} from "../../../auth/app.auth.context";
import AppAuthLoginFormModal from "../../../auth/app.auth.loginform.modal";
import {cancelLastRequest} from "../../../auth/app.auth.actions";
import {Link, useNavigate} from "react-router-dom";
// import {useHistory} from "react-router";

const QUERY_ORDERS_COUNT = gql`
    {
        getOrdersCount{
          count
        }
    }`

const Orders = () => {

    const {userDetails, loadingAuth, errorMessage, lastAction} = useAuthState();

    //const dispatch = useAuthDispatch();

/*
    if (userDetails) {
        console.log("######### UserDetails #######");
        console.log(userDetails);
        console.log("#############################")
    }
*/

    const [getOrderCount, {loading, error, data}] = useLazyQuery(QUERY_ORDERS_COUNT);
    const [count, setCount] = useState(0);
    const [showLogin, setShowLogin] = useState(false);

    //const navigate = useNavigate();

    if (!loading) {

        if (error) {
            console.log(' *** error *** ');
            console.log(error.message);
            console.log('***************');
        } else if (data && data['getOrdersCount']) {

            let res = data['getOrdersCount'].count

            if (count !== res) {
                setCount(res);

            }
        }

    } else {

    }


    const toggleLogin = () => {
        setShowLogin(!showLogin)
        // cancelLastRequest().catch();
    };

    const showAuth = () => {
        setShowLogin(true);
    }

    useEffect(() => {
        if (userDetails) {
            getOrderCount();
        }
    }, [ userDetails ]);

    const handleClick = () => {
        // href="/orders"
        // console.log('111')

        if (userDetails) {
            // history.push('/orders')
            window.location.assign("/orders");
        } else {
            showAuth();
        }
    }

    let linkOrder = userDetails ? '/orders' : '#';

    return (
        <div>
            <Link
                to={linkOrder}
                onClick={handleClick}
                className="nav-item nav-link d-none d-md-block"
            >
                <svg width="24" height="24"><use href="#icon-orders" xlinkHref="#icon-orders"/></svg>
                <small className="d-block">заказы</small>
                {(!loading && count > 0) && (
                    <span className="position-absolute top-0 end-0 mt-n1 me-2 badge rounded-pill bg-danger">
                        {(count >= 100) && '99+'}
                        {(count > 0 && count < 100) && count}
                    </span>
                )}
            </Link>
            <AppAuthLoginFormModal show={showLogin} toggle={toggleLogin} className="loginform" url='/orders'/>
        </div>
    )
}

export default Orders;
