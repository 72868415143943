import React, {useState} from 'react';
//import {Alert, Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Spinner from "react-bootstrap/Spinner";

const AppAuthLogoutForm = (props) => {

    const [isModalLogoutOpen,setModalLogoutOpen] = useState(false);

    const [isLogoutAlertOpen, setLogoutAlertOpen] = useState(false);
    const [logoutAlertMsg, setLogoutAlertMsg] = useState(false);

    const [logoutSpinner, setLogoutSpinner] = useState(false);
    const [btnLogoutDisabled, setBtnLogoutDisabled] = useState(false);

    const toggleModalLogout = () => setModalLogoutOpen(!isModalLogoutOpen);
    const toggleLogoutAlert = () => setLogoutAlertOpen(!isLogoutAlertOpen);


    function toggleLogout(){
        toggleModalLogout();
    }

    async function logoutSubmit(e) {
        // включение спиннера
        e.preventDefault();
        logoutClick();
    }

    function logoutClick(){

        setLogoutAlertOpen(false);
        setBtnLogoutDisabled(false);
        window.location.assign("/logout");
        //toggleModalLogout();
        //props.toggle();
    }

    const logoutHide = () => {

        props.toggle();
    }

    const cancelLogout = () => {

        props.toggle();
    }

    return (
        <Modal show={props.show}  id="modalLogout" backdrop={true} keyboard centered autoFocus={false} onHide={logoutHide}>
            <Form onSubmit={logoutSubmit} >
                <Modal.Header>Выход</Modal.Header>
                <Modal.Body>
                    Покинуть сайт?
                    <Alert show={isLogoutAlertOpen} >{logoutAlertMsg}</Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={logoutSpinner||btnLogoutDisabled} autoFocus variant="dark" className="carbonus-black-button" type="submit" id="logoutButton" ><span>Выйти</span>{logoutSpinner && <Spinner className="ms-2" size="sm" color="light" />}</Button>
                    <Button variant="secondary" onClick={cancelLogout} id="cancelButton">Отмена</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )

}

export default AppAuthLogoutForm;